<template>
    <section>
        <Header :white="true" />
        <WAppIcon link="https://wa.link/qlfpce" />
        <div class="header">
            <h1>Digital marketing</h1>
            <p class="subtitle">
                Todo comienza con una idea. <br class="only-mobile"> Una idea se vuelve una historia. <br class="only-pc">
                Y esa historia se convierte en un canal. <br class="only-mobile"> Que conquista, conecta, impacta.
            </p>
            <p class="txtMedio only-mobile">
                Hacemos campañas de marketing digital integrales que comuniquen con estrategia y creatividad los objetivos de tu marca. <br><br>
                Contamos con un equipo multidisciplinario formado por redactores, creativos, diseñadores y analistas que forman un team extraordinario y disruptivo.
            </p>
        </div>
        <div class="medio">
            <p class="txtMedio only-pc">
                Hacemos campañas de marketing digital integrales que comuniquen con estrategia y creatividad los objetivos de tu marca. <br><br>
                Contamos con un equipo multidisciplinario formado por redactores, creativos, diseñadores y analistas que forman un team extraordinario y disruptivo.
            </p>
            <div class="cuadricula">
                <div><img class="pic1" src="../assets/landingDigitalMarketing/img1.png" alt="Circulo LLantero"></div>
                <div class="txtCuadricula">
                    <a href="/agencia-de-marketing"><h2>Social Media</h2>
                    <p>Gestionamos la presencia online de tu marca en diversas plataformas, creando contenido atractivo
                         y planificando campañas estratégicas. Además, ajustamos estrategias, fortalecemos tu identidad
                        digital y aumentamos tu alcance de manera efectiva. <br><br>
                        Content Creation <br>
                        Community Management <br>
                        Influencer marketing <br>
                        Trends y estrategia 
                    </p></a>
                    <h2>Performance Marketing</h2>
                    <p>Prepárate para vender en línea tus productos y servicios.
                        Nuestras tiendas en línea están habilitadas para recibir 
                        pagos y procesar envíos a todas partes del mundo. <br><br>
                        Meta Ads <br>
                        Google Ads <br>
                        Youtube Ads
                    </p>
                    <a href="/agencia-seo"><h2>SEO</h2>
                    <p>
                        Aumenta la visibilidad de tu empresa en los motores
                        de búsqueda. Nos enfocamos en mejorar el posicionamiento
                        de tu sitio web para que tus clientes potenciales te 
                        encuentren fácilmente cuando busquen productos o servicios
                        como los que ofreces. <br><br>
                        Analytics <br>
                        Optimizacion de keywords <br>
                        Backlinks
                    </p></a>
                </div>
                <img class="pic2" src="../assets/landingDigitalMarketing/img2.png" alt="Cuppi">
                <img class="pic3" src="../assets/landingDigitalMarketing/img3.png" alt="Super del norte">
                <img class="pic4" src="../assets/landingDigitalMarketing/img4.png" alt="Sculptra">
                <img class="pic5" src="../assets/landingDigitalMarketing/img5.png" alt="Sushilitos">
            </div>
        </div>
        <div class="final">
            <h3>¿No encuentras lo <br class="only-mobile"> que necesitas? <br class="only-pc"> No dudes <br class="only-mobile"> en <a href="/home/contact-us">hablarnos.</a></h3>
        </div>
        <Footer :datos-footer="datosFooter" :landingWeb="true" />
    </section>
</template>
<script>
import Header from '../components/on-site/Header.vue';
import Footer from '../components/on-site/Footer.vue';
import WAppIcon from '../components/on-site/WAppIcon.vue';
export default {
    components: {
        Header,
        WAppIcon,
        Footer,
    },

    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            statusForm: '',
            imageSelected: null,
        }
    },

    metaInfo: {
        title: 'Digital Marketing',  
        meta: [
            {
                name: 'description',
                content: 'Nos encargamos del diseño y desarrollo de sitios web de manera personalizada que te ayudará a impulsarte como empresario o emprendedor hacia el éxito en ventas y posicionamiento de marca.'
            }
        ]
    },

}
</script>
<style scoped>
.header{
    width: 100vw;
    height: 50vw;
    padding-bottom: 10vw;
    background: url("../assets/landingDigitalMarketing/headerBg.png") no-repeat center,
    linear-gradient(180deg, rgba(152, 126, 243, 1) 90%, rgba(255, 255, 255, 0.5) 100%);
    background-size: 82.53%;
}
.header>h1, .subtitle{
    width: 63.958vw;
    font-size: 7.813vw;
    font-weight: 400;
    font-family: Gramatika-Medium;
    color: #FFFFFF;
    line-height: 121.3%;
    padding: 21.3vw 0vw 0vw 19.479vw;
    margin: 0vw;
}
.subtitle{
    width: 44.167vw;
    text-align: center;
    font-size: 1.146vw;
    line-height: 147%;
    padding-top: 8vw;
    padding-left: 24.188vw;
}
.medio{
    width: 100vw;
}
.txtMedio{
    width: 51.667vw;
    color: #000;
    text-align: center;
    font-family: "IBM Plex Mono";
    font-size: 0.833vw;
    font-style: normal;
    font-weight: 400;
    line-height: 179.8%; /* 28.768px */
    padding-left: 24.167vw;
    padding-bottom: 12.552vw;
}
.cuadricula{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
}
.pic1{
    grid-column: 1;
    grid-row: 1;
    width: 26.042vw;
    height: 19.792vw;
}
.txtCuadricula{
    grid-column: 2;
    grid-row: span 3;
}
.pic2{
    grid-column: 3;
    grid-row: 1;
    width: 15.052vw;
    height: 32.5vw;
    padding-left: 9.792vw;
}
.pic3{
    grid-column: 1;
    grid-row: 2;
    width: 21.302vw;
    height: 18.594vw;
    padding-left: 10.104vw;
}
.pic4{
    grid-column: 3;
    grid-row: 2;
    width: 21.771vw;
    height: 21.771vw;
    padding-left: 6.458vw;
    padding-top: 6.927vw;
}
.pic5{
    grid-column: 1;
    grid-row: 3;
    width: 15.26vw;
    height: 27.135vw;
    padding-left: 6.875vw;
    margin-top: -14vw;
}
.cuadricula h2, .cuadricula p{
    color: #EF67C2;
    font-family: "IBM Plex Mono";
    font-size: 0.833vw;
    font-style: normal;
    font-weight: 700;
    line-height: 179.8%; /* 28.768px */
    padding-top: 2vw;
    padding-left: 3vw;
}
.cuadricula p{
    color: #000;
    font-weight: 400;
    padding-top: 0vw;
    padding-bottom: 4.427vw;
}
.cuadricula h2::before{
    width: 2vw;
    height: .1vw;
    content: "";
    position: absolute;
    background-color: #EF67C2;
    margin-left: -2.5vw;
    margin-top: -2vw;
}
.final h3, .final h3 a{
    color: #B2ACFC;
    font-family: Gramatika-Medium;
    font-size: 2.604vw;
    font-style: normal;
    font-weight: 400;
    line-height: 150.8%;
    text-align: center;
    padding-top: 5vw;
    margin-bottom: 9.635vw;
}
.final h3 a{
    color: #EF67C2;
    text-decoration: none;
}
.only-pc{
    display: block;
}
.only-mobile{
    display: none;
}
@media (max-width:768px){
    .only-pc{
        display: none;
    }
    .only-mobile{
        display: block;
    }
    .header{
        height: 201.546vw;
        background: url("../assets/landingDigitalMarketing/headerMobile.png") no-repeat center,
        linear-gradient(180deg, rgba(152, 126, 243, 1) 50%, rgba(255, 255, 255, 0.5) 100%);
        background-position-y: 15%;
        background-size: 100%;
    }
    .header>h1, .subtitle{
        width: 66.087vw;
        font-size: 12.077vw;
        text-align: center;
        padding: 56.353vw 16.9565vw 14.493vw 16.9565vw;
    }
    .subtitle{
        padding-top: 0vw;
        font-size: 3.623vw;
    }
    .txtMedio{
        width: 76.087vw;
        font-size: 2.899vw;
        padding: 7.739vw 12.802vw;
    }
    .cuadricula{
        display: grid;
        grid-template-rows: repeat(2, 60vw) repeat(2, auto);
        grid-template-columns: repeat(2, 50vw);
    }
    .pic1{
        grid-row: 1;
        grid-column: 1;
        width: 74.638vw;
        height: 56.763vw;
    }
    .txtCuadricula{
        grid-row: 3;
        grid-column: span 2;
        height: 265.7vw;
        margin-top: 11.111vw;
        padding-right: 18.357vw;
        padding-left: 25.604vw;
    }
    .pic2{
        grid-row: 2;
        grid-column: 2;
        width: 36.957vw;
        height: 79.71vw;
        padding: unset;
        margin-top: -12vw;
        padding-left: 2vw;
    }
    .pic3{
        grid-row: 4;
        grid-column: 2;
        width: 75.845vw;
        height: 66.184vw;
        margin-left: -25.845vw;
    }
    .pic4{
        grid-row: 2;
        grid-column: 1;
        width: 31.401vw;
        height: 31.401vw;
    }
    .pic5{
        grid-row: 4;
        grid-column: 1;
        width: 54.348vw;
        height: 96.618vw;
        margin-top: 55vw;
        margin-left: 15.459vw;
    }
    .cuadricula h2, .cuadricula p{
        font-size: 2.899vw;
    }
    .cuadricula h2::before{
        width: 6vw;
        height: 1vw;
        margin-left: -8.5vw;
        margin-top: -2vw;
    }
    .final{
        margin-top: 10vw;
    }
    .final h3, .final h3 a{
        font-size: 4.831vw;
    }
}
</style>